import sendDataHandler from "../modules/sendDataHandler"
import updateURLParameter from "../methods/updateUrlParameter"

export default class Pagination {
  constructor(wrapper, options) {
    this.wrapperDOM = document.querySelector(wrapper)
    this.renderPlace = document.querySelector(options.renderPlace)
    this.paginationDOM = document.querySelector(options.paginationDOM)
    this.fetchApi = options.fetchApi
    this.renderCallback = options.renderCallback

    this.paginationData
    this._makeApiCallTable()
    this._bindEvents()
  }

  _bindEvents() {
    window.addEventListener("popstate", () => {
      this._makeApiCallTable()
    })
  }

  _makeApiCallTable(page) {
    /** Set new url */
    if (page) {
      let newURL = updateURLParameter(window.location.href, "page", page)
      window.history.replaceState({}, null, newURL)
      window.history.pushState({}, null, newURL)
    }

    let apiUrl = `${this.fetchApi}`

    /** Update query params with params already in user url */
    const params = new URLSearchParams(window.location.search.substr(1))
    let paramObj = []
    for (var value of params.keys()) {
      paramObj[value] = params.get(value)
      apiUrl = updateURLParameter(apiUrl, value, params.get(value))
    }

    apiUrl = updateURLParameter(apiUrl, "page", paramObj["page"] || 1)

    this.wrapperDOM.classList.add("is-loading")

    const callback = (flag, response) => {
      this.wrapperDOM.classList.remove("is-loading")

      if (!flag) {
        this.renderPlace.innerHTML = `<h5 class="h-ta-c h-mlr-a h-mt-5">${response.data.message}</h5>`
        this.paginationDOM.innerHTML = "";
      } else {
        const renderedItems = this.renderCallback(response.data)
        this.renderPlace.innerHTML = ""
        renderedItems.forEach(item => this.renderPlace.appendChild(item))
        this.paginationData = response.data.pagination
        console.log(this.paginationData)
        this.renderPagination()
      }
    }

    sendDataHandler({
      method: "GET",
      url: apiUrl,
      callback
    })
  }

  renderPagination() {
    // should render items by this search module
    let buttons = []
    if (this.paginationData.hasPreviousPage)
      buttons.push(
        `<div class="btn btn--red" title="Przejdź do strony ${this.paginationData.previousPage}" data-page='${this.paginationData.previousPage}'>Poprzednia strona</div>`
      )
    buttons.push(`<div class="btn btn--red h-ml-2 h-mr-2">${this.paginationData.currentPage}</div>`)
    if (this.paginationData.hasNextPage)
      buttons.push(
        `<div class="btn btn--red" title="Przejdź do strony ${this.paginationData.nextPage}" data-page='${this.paginationData.nextPage}'>Następna strona</div>`
      )

    this.paginationDOM.innerHTML = buttons.join("")

    const paginationItems = this.paginationDOM.querySelectorAll("[data-page]")
    paginationItems.forEach(item => item.addEventListener("click", () => this._makeApiCallTable(item.getAttribute("data-page"))))
  }
}
