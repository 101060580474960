import Swiper from 'swiper';

var mainSwiper = new Swiper('.swiper-container', {
	effect: "fade",
	fadeEffect: {
		crossFade: true
	},
	navigation: {
		nextEl: '.js-swiper-nav__next',
		prevEl: '.js-swiper-nav__prev',
	},
	autoplay: {
    delay: 5000,
  },
});

let sliderImages = document.querySelectorAll('.js-slide-image');
let mainSection = document.querySelector('.js-site-section--main');

mainSwiper.on('slideChange', function(){
	let image = sliderImages[mainSwiper.activeIndex].value;
	image = image.replace(/\\/g, '/');
	mainSection.style.backgroundImage = 'url(/'+image+')';
})