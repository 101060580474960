import sendDataHandler from "./sendDataHandler"

class Modal {
  constructor(modalContainerDOM) {
    this.modalContainerDOM = document.querySelector(modalContainerDOM)
    this.modalCancelDOM = this.modalContainerDOM.querySelectorAll(".js-cancel")
    this.modalTitleDOM = this.modalContainerDOM.querySelector(".js-title")
    this.modalContentDOM = this.modalContainerDOM.querySelector(".js-content")

    this.callbackOnModalClose
  }

  toggleModal({ callbackOnModalClose } = {}) {
    this.modalContainerDOM.classList.toggle("is-active")

    if (callbackOnModalClose && typeof callbackOnModalClose === "function") {
      this.callbackOnModalClose = callbackOnModalClose
    }
  }
}

class StatusModal extends Modal {
  constructor(modalContainerDOM) {
    super(modalContainerDOM)

    this._bindEvents()
  }

  _bindEvents() {
    this.modalCancelDOM.forEach(cancel => cancel.addEventListener("click", () => this.toggleModal()))
  }

  injectHTML({ title, content }) {
    console.log("injectHMTL")

    this.modalTitleDOM.innerHTML = title
    this.modalContentDOM.innerHTML = content
  }

  redirect({ url, timeout }) {
    setTimeout(() => (window.location.href = url), timeout)
  }
}

class UploadModal extends Modal {
  constructor(modalContainerDOM) {
    super(modalContainerDOM)
    this.loaderWrapperDOM = this.modalContainerDOM.querySelector(".js-loader-wrapper")

    this.files = []

    this.formDOM = this.modalContainerDOM.querySelector("form")
    this.modalGalleryDOM = this.modalContainerDOM.querySelector(".modal-gallery")
    this.modalGalleryItemsDOM

    this.proceedButtonDOM = this.modalContainerDOM.querySelector(".js-proceed")
    this.loadMoreButtonDOM = this.modalContainerDOM.querySelector(".js-load-more")
    this.selectImageButtonDOM = this.modalContainerDOM.querySelector(".js-select-image")

    this.selectedImage = {}

    this._bindEvents()
  }

  _bindEvents() {
    this.modalCancelDOM.forEach(cancel => cancel.addEventListener("click", () => this.toggleModal()))
    this.proceedButtonDOM.addEventListener("click", () => this.uploadFile())
    this.loadMoreButtonDOM.addEventListener("click", () => this.loadFiles())
    this.selectImageButtonDOM.addEventListener("click", () => this.chooseImage())
  }

  injectHTML({ title, content }) {
    this.modalTitleDOM.innerHTML = title
    this.modalContentDOM.innerHTML = content
  }

  uploadFile() {
    const inputDOM = this.formDOM.querySelector("[type=file]")

    const callback = (flag, response) => {
      console.log(response, flag)
      if (!flag) {
        /**
         * TODO: Handle ERROR
         */
        console.log("Uploading failed, message:", response.data.message)
      } else {
        this.loadFiles()
        inputDOM.value = ""
      }
    }

    const data = new FormData(this.formDOM)

    if (inputDOM.value !== "") {
      sendDataHandler({
        method: "post",
        url: "/admin/upload-file",
        data,
        type: "files",
        callback
      })
    }
  }

  loadFiles() {
    const callback = (flag, response) => {
      console.log(response)
      this.files = response.data.files
      this.loaderWrapperDOM.classList.remove("is-active")
      this.injectFilesHMTL()
    }

    const query = "?size=--xs&limit=30&skip=0"

    this.loaderWrapperDOM.classList.add("is-active")
    sendDataHandler({
      method: "get",
      url: "/api/files" + query,
      type: "files",
      callback
    })
  }

  injectFilesHMTL() {
    const fileHTML = file => {
      return `<div class="modal-gallery__item" id="${file._id}" data-extension="${file.extension}" data-filename="${file.originalFilename}" data-filepath="${file.mainPath}">
				<img src="/${file.path}" alt="image">
			</div>`
    }

    const allFilesHTML = this.files.map(file => {
      return fileHTML(file)
    })

    this.modalGalleryDOM.innerHTML = allFilesHTML.join("")

    this.modalGalleryItemsDOM = this.modalGalleryDOM.querySelectorAll(".modal-gallery__item")
    this.modalGalleryItemsDOM.forEach(item => item.addEventListener("click", event => this.markSelectedImage(event)))
  }

  markSelectedImage() {
    this.modalGalleryItemsDOM.forEach(item => item.classList.remove("is-active"))
    event.currentTarget.classList.add("is-active")
  }

  chooseImage() {
    const target = this.modalGalleryDOM.querySelector(".modal-gallery__item.is-active")
    this.selectedImage = {
      _id: target.getAttribute("id"),
      extension: target.getAttribute("data-extension"),
      filename: target.getAttribute("data-filename"),
      filepath: target.getAttribute("data-filepath")
    }

    if (this.callbackOnModalClose !== "") {
      this.callbackOnModalClose()
    }
  }
}

class ConfirmModal extends Modal {
  constructor(modalContainerDOM) {
    super(modalContainerDOM)
    this.modalConfirmBtnDOM = this.modalContainerDOM.querySelector(".js-proceed")
    this.modalLoadableDOM = this.modalContainerDOM.querySelector(".js-loadable")

    this._bindEvents()
    this.confirmCallback
  }

  _bindEvents() {
    this.modalCancelDOM.forEach(cancel => cancel.addEventListener("click", () => this.toggleModal()))
    this.modalConfirmBtnDOM.addEventListener("click", () => this.confirmAction())
  }

  confirmAction() {
    this.confirmCallback()
  }

  setLoading() {
    this.modalLoadableDOM.classList.add("is-loading")
  }

  setSuccess() {
    this.modalLoadableDOM.classList.remove("is-loading")
    this.modalLoadableDOM.classList.add("is-success")
  }

  setFailed() {
    this.modalLoadableDOM.classList.remove("is-loading")
    this.modalLoadableDOM.classList.add("is-failed")
  }

  injectHTML({ title, content, confirmCallback }) {
    // reset state
    this.modalLoadableDOM.classList.remove("is-loading")
    this.modalLoadableDOM.classList.remove("is-success")
    this.modalLoadableDOM.classList.remove("is-failed")

    this.modalTitleDOM.innerHTML = title
    this.modalContentDOM.innerHTML = content
    this.confirmCallback = confirmCallback
  }
}

let statusModal, uploadModal, confirmModal
try {
  statusModal = new StatusModal(".js-modal-container-status")
  uploadModal = new UploadModal(".js-modal-container-upload")
  confirmModal = new ConfirmModal(".js-modal-container-confirm")
} catch (e) {
  console.log(e)
}

export { statusModal, uploadModal, confirmModal }
