import sendDataHandler from "../modules/sendDataHandler"
import { statusModal, uploadModal, confirmModal } from "../modules/modal"
// import FormDataFetcher from "../modules/FormDataFetcher"
// import debounce from "../methods/debounce"

/**  Default callback */
const defaultCallback = (flag, response) => {
  if (!flag) {
    statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
    statusModal.toggleModal()
  } else {
    statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
    statusModal.toggleModal()
  }
}

/* ----------------------------- CHANGE BILLING ----------------------------- */
try {
  const btns = document.querySelectorAll(".js-update-offer-option-btn")

  btns.forEach(btn => {
    btn.addEventListener("click", e => {
      const form = btn.closest('.js-update-offer-option-form')
      console.log(form)
      let data = new FormData(form);
  
      sendDataHandler({
        method: "put",
        url: `/admin/api/offer-options`,
        data,
        callback: defaultCallback
      })
    })
  })

} catch (err) {}

window.removeGalleryPageItem = (event) => {
  let clickedItem = event;
  let albumId = clickedItem.getAttribute("albumId");
  let imageId = clickedItem.getAttribute("imageId");

  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
      clickedItem.remove();
    }
  }

  const data = new FormData();
  data.append('albumId', albumId);
  data.append('imageId', imageId);

  sendDataHandler({
    method: "POST",
    url: `/admin/dashboard/pages/galeria/delete-image`,
    data,
    callback
  })
}

window.removeAlbumTitle = (event) => {
  let clickedItem = event;
  let titleId = clickedItem.getAttribute("titleId");

  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
      document.getElementById(titleId).remove();
    }
  }

  const data = new FormData();
  data.append('titleId', titleId);

  sendDataHandler({
    method: "POST",
    url: `/admin/dashboard/pages/galeria/delete-album`,
    data,
    callback
  })
}

window.removeGalleryItem = (event) => {
  let clickedItem = event;
  let offerId = clickedItem.getAttribute("offerId");
  let galleryItemId = clickedItem.id;

  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
      clickedItem.remove();
    }
  }

  const data = new FormData();
  data.append('offerId', offerId);
  data.append('galleryItemId', galleryItemId);

  sendDataHandler({
    method: "POST",
    url: `/admin/dashboard/offers/edit-offer/gallery/delete`,
    data,
    callback
  })
}

window.postDeletePost = (event) => {
  event.preventDefault();

  let form = event.target;
  let postId = form.querySelector('.js-post-id').value;

  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
      document.getElementById(postId).remove();
    }
  }

  const data = new FormData();
  data.append('postId', postId);

  sendDataHandler({
    method: "POST",
    url: `/admin/dashboard/posts/delete-post`,
    data,
    callback
  })
}

window.postDeleteOffer = (event) => {
  event.preventDefault();
  let btn = event.target;
  let offerId = btn.getAttribute('data-id');
  console.log(offerId)

  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
      document.getElementById(offerId).remove();
    }
  }

  const data = new FormData();
  data.append('offerId', offerId);

  sendDataHandler({
    method: "POST",
    url: `/admin/dashboard/offers/delete-offer`,
    data,
    callback
  })
}

window.putSubmit = (e) => {
	if(e.preventDefault) e.preventDefault();
	const target = e.target;
	const url = target.getAttribute('data-href');
	const data = new FormData(target);

	sendDataHandler({
		method: "PUT",
		url: url,
		data,
		callback: defaultCallback
	})
}

window.deleteSubmit = (e) => {
	if(e.preventDefault) e.preventDefault();
	const target = e.target;
	const url = target.getAttribute('data-href');
	const el = target.getAttribute('data-el')
	const data = new FormData(target);

	const cb = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
			if(el){
				const elDOM = document.getElementById(el);
				if(elDOM) elDOM.remove();
			}
    }
  }

	const c = confirm("Czy na pewno to usunąć?");
	if(!c) return;

	sendDataHandler({
		method: "DELETE",
		url: url,
		data,
		callback: cb
	})
}