import Choices from "choices.js";
import axios from "axios";
import updateURLParameter from "../methods/updateUrlParameter";
// import getUrlParameter from "../methods/getUrlParameter"
import pushUrl from "../methods/pushUrl";
import sendDataHandler from "../modules/sendDataHandler";

try {
	const elements = document.querySelectorAll(".js-choice");
	if (elements.length > 0) {
		elements.forEach(el => {
			new Choices(el, {
				itemSelectText: ""
			});
		});
	}
} catch (err) {}

try {
	const choicesArray = [];

	// const getSelectItems = (choices, name) => {
	//   const callback = (flag, response) => {
	//     if(flag){
	//       if(response.data.items.length > 0){
	//         if(response.data.items[0][name]){
	//           let arr = [{value: '', label: 'Wszystkie'}];
	//           response.data.items[0][name].forEach(item => {
	//             arr.push({ value: item, label: item })
	//           })

	//           choices.clearChoices();
	//           choices.setChoices(arr);
	//         }
	//       }
	//     }
	//   }

	//   const params = window.location.search

	//   sendDataHandler({
	//     method: "GET",
	//     url: "/api/offers/selects" + params + `&property=${name}`,
	//     callback
	//   })
	// }

	function removeURLParameter(url, parameter) {
		//prefer to use l.search if you have a location/link object
		var urlparts = url.split("?");
		if (urlparts.length >= 2) {
			var prefix = encodeURIComponent(parameter) + "=";
			var pars = urlparts[1].split(/[&;]/g);

			//reverse iteration as may be destructive
			for (var i = pars.length; i-- > 0; ) {
				//idiom for string.startsWith
				if (pars[i].lastIndexOf(prefix, 0) !== -1) {
					pars.splice(i, 1);
				}
			}

			return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
		}
		return url;
	}

	const getSelectItems = name => {
		const callback = (flag, response) => {
			if (flag) {
				const items = response.data.items[0];

				/** We get from backend a list of selects names that have to be purged */
				if (response.data.selectsToReset.length > 0) {
					for (let i = 0; i < choicesArray.length; i++) {
						const elementName = choicesArray[i].passedElement.element.name;
						if (response.data.selectsToReset.includes(elementName)) {
							let arr = [{ value: "", label: "Brak" }];
							choicesArray[i].clearChoices();
							choicesArray[i].setChoices(arr);
							choicesArray[i].setChoiceByValue("");
							const url = removeURLParameter(window.location.href, elementName);
							pushUrl(url);
						}
					}
				}

				/** Data is in response.data.items[0] */
				if (response.data.items.length > 0) {
					for (let i = 0; i < choicesArray.length; i++) {
						const elementName = choicesArray[i].passedElement.element.name;
						if (items[elementName]) {
							let arr = [{ value: "", label: "Wszystkie" }];
							items[elementName].forEach(elem => {
								if (Array.isArray(elem)) {
									elem.forEach(el => {
										arr.push({ value: el, label: el });
									});
								} else {
									arr.push({ value: elem, label: elem });
								}
							});

							choicesArray[i].clearChoices();
							choicesArray[i].setChoices(arr);
							choicesArray[i].setChoiceByValue("");
						}
					}
				}
			}
		};

		const params = window.location.search;

		sendDataHandler({
			method: "GET",
			url: "/api/offers/selects" + params + `&property=${name}`,
			callback
		});
	};

	const elements = document.querySelectorAll(".js-choice-filter");
	if (elements.length > 0) {
		elements.forEach(el => {
			const choicesSort = new Choices(el, {
				itemSelectText: "",
				shouldSort: false,
				shouldSortItems: false
			});

			choicesArray.push(choicesSort);

			choicesSort.passedElement.element.addEventListener("change", function(e) {
				let newURL = updateURLParameter(
					window.location.href,
					el.name,
					e.detail.value
				);
				newURL = updateURLParameter(newURL, "page", 1);
				pushUrl(newURL);
			});

			choicesSort.passedElement.element.addEventListener("change", function(
				event
			) {
				const name = event.srcElement.name;
				getSelectItems(name);
			});
			getSelectItems("type");
		});
	}

	/** initialize selects properties on page load */
	getSelectItems("type_category");

	window.resetSearchForm = () => {
		for (let i = 0; i < choicesArray.length; i++) {
			choicesArray[i].setChoiceByValue("");
			document.querySelectorAll(".date-picker").forEach(d => (d.value = ""));
			var url = window.location.href.split("?")[0];
			pushUrl(url);
		}
	};

	// window.addEventListener("popstate", () => {
	// 	getSelectItems();
	// });
} catch (err) {
	console.log(err);
}
