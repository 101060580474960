import flatpickr from "flatpickr";
import {Polish} from "flatpickr/dist/l10n/pl.js";

import updateURLParameter from "../methods/updateUrlParameter"
import pushUrl from "../methods/pushUrl"

const datepicker = flatpickr(".date-picker", {
  dateFormat: 'Y-m-d',
  "locale": Polish,
  onOpen: function () {
    let thatInput = this._input;
    thatInput.parentNode.classList.add('is-active');
  },
  onClose: function () {
    let thatInput = this._input;
    thatInput.parentNode.classList.remove('is-active');
  },
  onChange: function (selectedDates, dateStr, instance) {
    let newURL = updateURLParameter(window.location.href, instance.input.name, dateStr)
    newURL = updateURLParameter(newURL, "page", 1)
    pushUrl(newURL)
  }
});