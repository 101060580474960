import Pagination from "../modules/pagination"
import moment from 'moment';
// import PhraseSearch from "../modules/phraseSearch"
// import moment from "moment"

/* -------------------------------------------------------------------------- */
/*                                OFFERS TABLE                                */
/* -------------------------------------------------------------------------- */
try {
  const callback = data => {
    const render = item => {
      const row = document.createElement("div")
      row.classList = "col-xl-4 col-lg-6 col-12 h-mt-5 col-padded"
      row.id = item._id

      let hasExpired = '';
      if(item.expiration_date && item.expiration_date <= new Date()) hasEpired = 'is-inactive';

      let areDaysSet = false;
      if(item.start_date && item.end_date) areDaysSet = true;

      let startDate = moment(item.start_date)
      let endDate = moment(item.end_date)
      let daysDiff = endDate.diff(startDate, 'days') + 1;

      let daysRender = "";
      if(areDaysSet) daysRender = `
        <div class="date">
          <img src="/images/build/calendar.svg" alt="Calendar">
          ${daysDiff}
        </div>
      `;

      let pricePromo = '';
      if(item.price_promo) pricePromo = `
        <div class="offer-banner__alert">
          <img src="/images/build/bell.svg" alt="Bell">Promocja
        </div>`

      let placeRating = [];
      for(let i = 0; i < item.accomodation_place_rating; i++){
        placeRating.push(`<img src="/images/build/star.svg" alt="Star">`);
      }


      let price = `<div class="${item.price_promo}">od ${item.price} zł/os</div>`;
      
      if(item.price_promo) price = `
        <div class="offer-price__before">od ${item.price} zł/os</div>
        <div class="offer-price__after">od ${item.price_promo} zł/os</div>
      `

      row.innerHTML = `
        <article class="offer-item ${hasExpired}">
          <a href="/oferta/${item.slug}" title="${item.title}">
            <div class="offer-banner">
              <img src="/${item.image}" class="offer-banner__image" alt="${item.title}">
              ${pricePromo}
            </div>
            <header class="offer-header">
              <h4 class="offer-header__heading">${item.title}</h4>
              <h5 class="offer-header__subheading">${item.accomodation_place}</h5>
              <div class="offer-rating h-jc-e">
                ${placeRating.join('')}
              </div>
            </header>
            <footer class="offer-footer row h-jc-sb h-ai-c">
              ${daysRender}
              <div class="offer-price">
                ${price}
              </div>
            </footer>
            <div class="btn btn--fwide btn--offset">Zobacz ofertę</div>
          </a>
        </article>
        `
      return row
    }

    const renderedData = data.items.map(item => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-offer-tiles", {
    fetchApi: "/api/offers",
    renderPlace: ".js-tiles-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback
  })
} catch (err) {}