const CookieConsent = function (args) {
	const that = this;

	this.expTime = args.expirationDays;

	this.init = function () {
		this.cacheDom();
		bindEvents();
	}

	this.cacheDom = function () {
		
		this.cookie = document.querySelector(args.cookieWrapper);
		this.cancel = this.cookie.querySelector(args.cookieCancel);
	};

	function bindEvents() {
		that.cancel.addEventListener('click', hideConsent);

		// check if cookie is already set
		(document.cookie.indexOf("cookie-consent") >= 0) ? '' : showConsent();
	}

	function showConsent() {
		that.cookie.classList.add('is-active');
	}

	function hideConsent() {
		that.cookie.classList.remove('is-active');

		// set cookie time expiration
		let expireTime = new Date();
		expireTime.setTime(expireTime.getTime() + (60 * 60 * 24 * 1000 * this.expTime));
		document.cookie = "cookie-consent=true; expires=" + expireTime.toGMTString();
	}
};

module.exports = CookieConsent;