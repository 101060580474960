const Accordion = function (args) {
  const that = this;

	this.init = function () {
		this.cacheDom();
		bindEvents();
	}

	this.cacheDom = function () {
    this.main_item = document.querySelectorAll(args.main_item);
	};

	function bindEvents() {
		for(let i=0; i<that.main_item.length; i++){
      that.main_item[i].addEventListener('click', activate);
    }
	}

	function activate() {
    for(let i=0; i<that.main_item.length; i++){
      if(i !== [...that.main_item].indexOf(this)){
        that.main_item[i].parentNode.classList.remove('is-active');
      }
    }
    this.parentNode.classList.toggle('is-active');
	}
};

export default Accordion;