import '../scss/style.scss';

import './vendors/polyfills';
// import CookieConsent from './modules/cookie-consent.js';

import './modules/lazy-load';
import './use-cases/sliders';
import './use-cases/choices';
import './use-cases/datepickers';
import './use-cases/paginated-content';
import './use-cases/forms-actions';

import MobileNavigation from './modules/mobile-navigation.js';
import Accordion from './modules/accordion.js';
import ScrollAction from './modules/scroll-action.js';
import html2pdf from 'html2pdf.js';


window.printOrder = function(){
  var source = document.querySelector('#print');

  var opt = {
    margin:0.2,
    filename:     'podglad.pdf',
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { scale: 2 },
    jsPDF:        { unit: 'in', format: 'a2', orientation: 'portrait' }
  };

  html2pdf().from(source).set(opt).save();
}

if (window.location.href.indexOf("#print") != -1){
  printOrder();
}





import ScrollReveal from 'scrollreveal';

import CookieConsent from './modules/cookie-consent.js';

try{

  const cookie = new CookieConsent({
    cookieWrapper: '.js-cookie-consent',
    cookieCancel: '.js-cookie-cancel',
    expirationDays: 30
  });
  cookie.init();

}catch(err){}


var slideUp = {
  distance: '25%',
  origin: 'bottom',
  opacity: 0,
  easing: 'ease-in-out',
  duration: 800,
  viewFactor:0.15
};

ScrollReveal().reveal('.reveal', slideUp);


import './modules/modernizr-custom';

try{
  const aside_menu = new Accordion({
    main_item:'.js-main-list-item'
  });
  aside_menu.init();
}catch(err){}

try{
  let mobileNav = new MobileNavigation({
    activator: '.js-menu-icon',
    cancel: '.js-mobile-menu-cancel',
    header: 'body',
  });
  mobileNav.init();
}catch(err){}

try{
  if(document.querySelector('.js-top')){
    const scrollAction = new ScrollAction({
      wrapper: '.js-top', // element which get class - 'classToAdd' after reaching offset
      offset: ".js-top-bar",   // number || div, that points the offset from top
      classToAdd: "is-sticky"
    });
    scrollAction.init();
  }
}catch(err){}
/* ========================================================================== */
/*                                SOCKET SETUP                                */
/* ========================================================================== */
// let socket = io.connect(server);

/* ========================================================================== */
/*                                 NEWSLETTER                                 */
/* ========================================================================== */
// window.signInNewsletter = function (event) {
//   event.preventDefault();

//   let form = event.target;
//   let email = form.querySelector('input[name="email"]').value;
//   let token = form.querySelector('input[name="_csrf"]').value;


//   var xhr = new XMLHttpRequest();
//   xhr.onreadystatechange = function () {
//     if (xhr.readyState == XMLHttpRequest.DONE) {
//       console.log(xhr.responseText);
//     }
//   }
//   xhr.open("POST", server+'newsletter', true);
//   xhr.setRequestHeader('CSRF-Token', token);
//   xhr.setRequestHeader('Content-Type', 'application/json')
//   xhr.send(JSON.stringify({
//     email: email,
//   }));
// }

// socket.on('newsletter', function (message) {
//   let newsletterForm = document.querySelector('.js-form-newsletter');
//   let newsletterAlert = newsletterForm.parentNode.querySelector('.js-form-newsletter-alert');

//   newsletterAlert.innerHTML = "";
//   newsletterAlert.classList.remove('is-active');
//   debugger;
//   if (message.success) {

//     newsletterForm.classList.add('is-hidden');
//     setTimeout(function () {
//       newsletterForm.remove(newsletterForm);
//       newsletterAlert.innerHTML = message.message;
//       newsletterAlert.classList.add('is-active');
//     }, 500)
//   } else {
//     newsletterAlert.classList.add('is-active');
//     newsletterAlert.innerHTML = message.message;
//     setTimeout(()=>{
//       newsletterAlert.innerHTML = "";
//       debugger;
//     },2500);
//   }
// })



try{

  /* ------------------------------ scroll to top ----------------------------- */
  document.querySelector('.js-scroll-up').addEventListener("click", function(event) {
    event.preventDefault();
    var offset = 0, y = 0, dy, call = setInterval(function(){
      if( Math.abs(dy=offset-y)>1 ) y += dy/8;
      else { clearInterval(call); y = offset; }
      document.documentElement.scrollTop = y;
    },10);
    offset = document.querySelector('body').offsetTop;
    y = document.documentElement.scrollTop;
  });
  
}catch(err){}

try{
  //user-account icon
  document.querySelector('.js-user-account').addEventListener('click', function(){
    this.classList.toggle('is-clicked');
  })
}catch(err){}
  

try{
  let getPrice = document.querySelector('.js-start-price').value;
  let finalPrice = document.querySelector('.js-final-price');


  if(finalPrice != null && finalPrice != undefined){
    let priceChangers = document.querySelectorAll('.js-price-changer');
    console.log(priceChangers);
    for(let i = 0; i<priceChangers.length; i++){
      priceChangers[i].addEventListener('change', function(){
        calculatePrice()
      })
    }
  }

  function calculatePrice(){
    // console.log(priceChangers[i].getAttribute('price'));
    let dojazd = document.querySelector('.js-select-changer');

    let cost = 0;

    let dojazdPrice;
    if(dojazd){
      dojazdPrice = dojazd.options[dojazd.selectedIndex].getAttribute("price");
    }else{
      dojazdPrice = 0;
    }
    let rabaty = document.querySelectorAll('.js-checkbox-changer');
    for(let i=0; i < rabaty.length; i++){
      if(rabaty[i].checked){
        let percentage = rabaty[i].getAttribute('percentage');
        let price = rabaty[i].getAttribute('price');

        if(JSON.parse(percentage)){
          cost = Number(cost) + (Number(getPrice) * (price / 100));
        }else{
          cost = Number(cost) + Number(price);
        }
      }

    }

    finalPrice.innerHTML = Number(getPrice) + Number(dojazdPrice) + Number(cost) + " zł/os";
  }

  calculatePrice();
}catch(err){

}
