import axios from 'axios';

let baseURL = 'http://localhost:8080';
if(process.env.NODE_ENV == 'development'){
  baseURL = 'http://46.238.215.3:8080/'
}else if(process.env.NODE_ENV == 'production'){
  baseURL = 'https://www.krystad.pl'
}


console.log(baseURL, process.env.NODE_ENV);
const instance = axios.create({
  baseURL,
  validateStatus: function (status) {
    return status >= 200 && status <= 503;
  },
});

instance.defaults.withCredentials = true;
instance.defaults.credentials = 'include';

export default instance;