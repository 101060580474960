let MobileNavigation = function(args){
    const that = this;
    this.flag = false;

    this.init = function(){
        this.cacheDom();
        bindEvents();
    }

    this.cacheDom = function(){
        this.activator = document.querySelector(args.activator);
        this.cancel = document.querySelector(args.cancel);
        this.header = document.querySelector(args.header);
    }
    
    function bindEvents(){
        that.activator.addEventListener('click', function(){ that.showHide(); });
        if(that.cancel){
            that.cancel.addEventListener('click', that.showHide);
        }
    }
    
    // przepisac na dwie metody - show oraz hide
    this.showHide = function(){
        if(!that.flag){
            that.header.classList.add('is-mobile-navigation-active');
            that.activator.classList.add('is-active');
            that.flag = true;
        }else{
            that.header.classList.remove('is-mobile-navigation-active');
            that.activator.classList.remove('is-active');
            that.flag = false;
        }
    }
}

module.exports = MobileNavigation;