let ScrollAction = function(args){
    const that = this;
    this.wrapper = args.wrapper;
    this.offset = (args.offset) ? args.offset : 0;
    this.classToAdd = args.classToAdd;

    let topBar = document.querySelector('.js-top-bar');

    this.init = function(){
      this.cacheDom();
      bindEvents();
    }

    this.cacheDom = function(){
      this.el = document.querySelector(this.wrapper);
    }

    function bindEvents(){
      window.addEventListener('scroll', decideAction);
      window.addEventListener('load', decideAction);
    }

    function decideAction(){
      if(typeof that.offset === "number"){
        makeAction(that.offset);
      }else if(typeof that.offset === "string"){
        let hookMoment = document.querySelector(that.offset);
        makeAction(document.querySelector(that.offset).offsetTop);
      }
    }

    function makeAction(argument){
      console.log(topBar.clientHeight);
      let scrollTop = window.pageYOffset;
      if(scrollTop > topBar.clientHeight){
        that.el.style.transform = "translateY(-"+ topBar.clientHeight +"px)";
      }else{
        that.el.classList.remove(that.classToAdd);
        that.el.style.transform = "translateY(0)";
      }
    }
}
module.exports = ScrollAction;
